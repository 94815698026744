<template>
  <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
    <v-container fluid>
      <v-row
        dense
        v-permission="{
          roles: ['F1', 'F2', 'F3', 'CC1', 'CC2'],
          operator: '!=='
        }"
      >
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-file-input
            id="file"
            name="file"
            label="Arquivo"
            dense
            outlined
            required
            :rules="[required]"
            v-model="file"
          />
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="2">
          <BaseButton
            id="btn-save"
            name="btn-save"
            color="secondary"
            title="Adicionar"
            height="40"
            :disabled="!valid"
            @click="uploadDocument"
          />
        </v-col>
      </v-row>

      <BaseTableList dense title="Listagem de documentos">
        <template v-slot:table>
          <v-data-table
            dense
            disable-sort
            item-key="id"
            :headers="headers"
            :items="items"
            :items-per-page="10"
            :options.sync="options"
            :server-items-length="totalRecords"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 50, 100],
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'chevron_left',
              nextIcon: 'chevron_right'
            }"
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDate(item.createdAt) }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="success"
                    class="mr-2"
                    small
                    @click="downloadDocument(item)"
                  >
                    fa-solid fa-download
                  </v-icon>
                </template>
                <span>Baixar arquivo</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="error"
                    small
                    @click="deleteDocument(item)"
                    v-permission="{
                      roles: ['F1', 'F2', 'F3', 'CC1', 'CC2'],
                      operator: '!=='
                    }"
                  >
                    fa-regular fa-trash-can
                  </v-icon>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </template>
      </BaseTableList>
    </v-container>
  </v-form>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { mapGetters } from 'vuex';
import { formatDate } from '@/helpers/formatting';
import { downloadFile } from '@/helpers/download';
import { confirmMessage, showMessage } from '@/helpers/messages';
import { InvoiceService } from '@/services/api/invoice';

export default {
  mixins: [rulesMixin],

  data: () => ({
    file: null,
    valid: true,
    options: {},
    headers: [
      { text: 'Nome', value: 'name' },
      { text: 'Data de envio', value: 'createdAt' },
      { text: '', value: 'action' }
    ],
    items: [],
    totalRecords: 0
  }),

  computed: {
    ...mapGetters({
      invoice: 'invoice/getInvoice'
    })
  },

  methods: {
    formatDate,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;

        const invoiceService = new InvoiceService();
        const { status, data } = await invoiceService.getDocuments({
          page,
          take: itemsPerPage,
          invoiceId: this.invoice.id
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async uploadDocument() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const invoiceService = new InvoiceService();
        const { status } = await invoiceService.uploadInvoiceDocument({
          invoiceId: this.invoice.id,
          file: this.file
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.$refs.form.reset();
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async downloadDocument(item) {
      try {
        const invoiceService = new InvoiceService();
        const { status, data } = await invoiceService.downloadInvoiceDocument({
          documentId: item.id,
          invoiceId: this.invoice.id
        });

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async deleteDocument(item) {
      const result = await confirmMessage(
        'Atenção!',
        'Deseja realmente excluir o registro selecionado?'
      );

      if (result) {
        try {
          const invoiceService = new InvoiceService();
          const { status } = await invoiceService.removeInvoiceDocument({
            documentId: item.id,
            invoiceId: this.invoice.id
          });

          if (status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
