var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{directives:[{name:"permission",rawName:"v-permission",value:({
        roles: ['F1', 'F2', 'F3', 'CC1', 'CC2'],
        operator: '!=='
      }),expression:"{\n        roles: ['F1', 'F2', 'F3', 'CC1', 'CC2'],\n        operator: '!=='\n      }"}],attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('v-file-input',{attrs:{"id":"file","name":"file","label":"Arquivo","dense":"","outlined":"","required":"","rules":[_vm.required]},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"2"}},[_c('BaseButton',{attrs:{"id":"btn-save","name":"btn-save","color":"secondary","title":"Adicionar","height":"40","disabled":!_vm.valid},on:{"click":_vm.uploadDocument}})],1)],1),_c('BaseTableList',{attrs:{"dense":"","title":"Listagem de documentos"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"dense":"","disable-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.items,"items-per-page":10,"options":_vm.options,"server-items-length":_vm.totalRecords,"footer-props":{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.downloadDocument(item)}}},on),[_vm._v(" fa-solid fa-download ")])]}}],null,true)},[_c('span',[_vm._v("Baixar arquivo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({directives:[{name:"permission",rawName:"v-permission",value:({
                    roles: ['F1', 'F2', 'F3', 'CC1', 'CC2'],
                    operator: '!=='
                  }),expression:"{\n                    roles: ['F1', 'F2', 'F3', 'CC1', 'CC2'],\n                    operator: '!=='\n                  }"}],attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteDocument(item)}}},on),[_vm._v(" fa-regular fa-trash-can ")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}}],null,true)})]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }